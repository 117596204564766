import moment from 'moment';
import i18n from 'i18next';
import { formatDuration } from 'utils/Helpers';
import { serviceName } from 'utils/Reserbus';
import { camelize } from 'humps';
import { generateGoogleMapsSearchUrl } from 'utils/urls';

/**
 * @typedef {Object} StopRaw
 * @property {string} terminal example: "Terminal de Autobuses" | "Morelia, Terminal de Autobuses"
 * @property {string} departure example: "2018-12-03 17:28:35 -0600"
 * @property {string} arrival example: "2018-12-03 17:48:35 -0600"
 * @property {string} [cityName] example: "Morelia"
 */

/**
 * @typedef {Object} pathSegmentRaw
 * @property {number} schedule example: 1234
 * @property {string} provider example: "SCP"
 * @property {string} origin example: "MONTERREY, MX"
 * @property {string} destination example: "LAREDO, MX"
 * @property {string} departure example: "2018-12-03 17:08:35 -0600"
 * @property {string} arrival example: "2018-12-03 17:58:35 -0600"
 * @property {StopRaw[]} stops
 */

const dateFormat = 'DD MMM YY';
const hourFormat = 'LT';

/** Parse the stops of a trip
 * @param {StopRaw[]} stops
 */
const parseStops = (stops) =>
  stops.map(({ arrival, departure, terminal, cityName }) => {
    const departureDate = moment(departure);
    const arrivalDate = moment(arrival);
    const duration = moment.duration(departureDate.diff(arrivalDate));
    const durationAsMinutes = formatDuration(duration.asMinutes(), 'm');
    const durationCopy =
      durationAsMinutes > 0 ? `de ${formatDuration(durationAsMinutes, 'm')}` : false;

    return {
      type: 'stop',
      // Fallback to terminal if cityName is not provided, case of use Brasilia
      title: cityName || terminal,
      description: durationCopy
        ? i18n.t('trips:time_stopover_in', { time: durationCopy, place: terminal })
        : i18n.t('trips:stopover_in', { place: terminal }),
      time: [
        { title: i18n.t('trips:arrives'), time: arrivalDate.format(hourFormat) },
        { title: i18n.t('trips:leaves'), time: departureDate.format(hourFormat) },
      ],
    };
  });

/**
 * Parse the path of a trip
 * @param {pathSegmentRaw[]} path
 */
function parsePath(path, originName, destinationName) {
  return path.reduce((timeLine, segment, index) => {
    if (index > 0) {
      const { schedule } = path[index - 1];
      const transferCopy = `Cambiar de autobús a ${schedule}`;
      timeLine.push({
        type: 'transfer',
        description: transferCopy,
      });
    }

    const departureCopy = moment(segment.departure).format(hourFormat);
    timeLine.push({
      type: index === 0 ? 'departure' : 'place',
      time: departureCopy,
      title: segment.origin,
      description: i18n.t('trips:leaves_from', { place: originName ?? segment.origin }),
    });

    timeLine = [...timeLine, ...parseStops(segment.stops)];

    const arrivalCopy = moment(segment.arrival).format(hourFormat);
    timeLine.push({
      type: index === path.length - 1 ? 'end' : 'place',
      time: arrivalCopy,
      title: segment.destination,
      description: i18n.t('trips:arrives_in', {
        place: destinationName ?? segment.destination,
      }),
    });

    return timeLine;
  }, []);
}

/**
 * Parse the amenities of a trip
 * @param {Object} params - object with params
 * @param {string} params.lineId - line id
 * @param {*} lines - lines object
 * @returns {Object} - amenities of the trip
 */
const parseAmenities = ({ lineId }, lines) => {
  const lineDetails = lines[camelize(lineId)];

  if (lineDetails) {
    const { logoUrl, serviceType, services } = lineDetails;

    return {
      logo: logoUrl,
      service: serviceName(serviceType),
      amenities: services,
    };
  }

  return {};
};
/**
 * Parse de details of a trip
 * @param {Object} params - object with params
 * @param {*} params.trip - trip object
 * @param {*} params.lines - lines object
 * @param {*} params.terminals - terminals object
 * @returns {Object} - trip details
 */
export default function parseTripDetails({ trip, lines, terminals }) {
  const {
    departure,
    arrival,
    path,
    destinationAddress,
    destinationId,
    originAddress,
    originId,
    stops,
    lineId,
  } = trip;

  const departureDate = moment(departure);
  const arrivalDate = moment(arrival);
  const duration = moment.duration(arrivalDate.diff(departureDate));
  const departureTime = moment(departure).format('LT');
  const arrivalTime = moment(arrival).format('LT');
  const originName = terminals?.[camelize(originId)].name;
  const originCityName = terminals?.[camelize(originId)].cityName;
  const destinationName = terminals?.[camelize(destinationId)].name;
  const destinationCityName = terminals?.[camelize(destinationId)].cityName;

  const details = {
    originAddress,
    destinationAddress,
    originCoordinates: terminals?.[camelize(originId)].coordinates,
    destinationCoordinates: terminals?.[camelize(destinationId)].coordinates,
    originName,
    originCityName,
    destinationName,
    destinationCityName,
    departureDate: departureDate.format(dateFormat),
    arrivalDate: arrivalDate.format(dateFormat),
    departureTime,
    arrivalTime,
    tripDuration: formatDuration(duration.asMinutes(), 'm'),
    route: path ? parsePath(path, originName, destinationName) : null,
    footer: parseAmenities(trip, lines),
    lineDetails: lines[camelize(lineId)],
    stops,
  };

  return details;
}

/**
 *  Check if the hour is early morning
 */
export const earlyMorningEval = (hour) => hour >= 0 && hour < 7;
/**
 * Check if the hour is morning
 */
export const morningEval = (hour) => hour >= 7 && hour < 12;
/**
 * Check if the hour is afternoon
 */
export const afternoonEval = (hour) => hour >= 12 && hour < 19;
/**
 * Check if the hour is night
 */
export const nightEval = (hour) => hour >= 19 && hour < 24;

/**
 * Returns the day time that matches with the departure time range.
 * @param {string} departureTime - trip departure time.
 * @returns {string} Day time that match with the departure time range.
 */
export const getDayTime = (departureTime) => {
  if (earlyMorningEval(departureTime)) return 'earlymorning';
  if (morningEval(departureTime)) return 'morning';
  if (afternoonEval(departureTime)) return 'afternoon';
  return 'night';
};

/**
 * Get the terminal information of a trip.
 * @param {Object} params - object with params
 * @param {Object} params.originTerminal - origin terminal
 * @param {Object} params.destinationTerminal - destination terminal
 * @returns {Object} - terminal information of the trip
 */
export const getTripTerminalInfo = ({ originTerminal, destinationTerminal }) => {
  if (!originTerminal) return {};
  return {
    originTerminal: originTerminal.name,
    destinationTerminal: destinationTerminal.name,
    originCity: originTerminal.cityName,
    destinationCity: destinationTerminal.cityName,
    originCoords: originTerminal.coordinates,
    destinationCoords: destinationTerminal.coordinates,
    originMapUrl:
      originTerminal.coordinates &&
      generateGoogleMapsSearchUrl({
        lat: originTerminal.coordinates.lat,
        long: originTerminal.coordinates.long,
      }),
    destinationMapUrl:
      destinationTerminal.coordinates &&
      generateGoogleMapsSearchUrl({
        lat: destinationTerminal.coordinates.lat,
        long: destinationTerminal.coordinates.long,
      }),
    hasCoords: Boolean(
      originTerminal.coordinates?.lat &&
        originTerminal.coordinates.long &&
        destinationTerminal.coordinates?.lat &&
        destinationTerminal.coordinates.long,
    ),
  };
};
