import moment from 'moment';
import { decamelizeKeys } from 'humps';
import growthBook from '../services/growthBook';

/**
 * Translates the passenger category code to a proper name
 * ```
 * adult => Adulto
 * child => Niño
 * infant => Bebé
 * ```
 * @method categoryName
 * @param {String} type Passenger category code received from the API
 * @return {String}
 */
export function categoryName(type) {
  switch (type) {
    case 'adult':
      return 'Adulto';
    case 'child':
      return 'Niño';
    case 'infant':
      return 'Bebé';
    default:
      return type;
  }
}

/**
 * Translates the bus discount code to a proper name
 * ```
 * teacher => Profesor
 * student => Estudiante
 * ···
 * older => INSEN
 * minor => Menor
 * general => Sin descuento
 * ```
 * @method busTicketCategory
 * @param {String} type Passenger category code received from the API
 * @return {String}
 */
export function busTicketCategory(type) {
  switch (type) {
    case 'adult':
      return 'Adulto';
    case 'child':
      return 'Niño';
    case 'infant':
      return 'Bebé';
    case 'general':
      return 'Adulto';
    case 'minor':
      return 'Menor (3 a 11 años)';
    case 'student':
      return 'Estudiante';
    case 'teacher':
      return 'Profesor';
    case 'older':
      return 'INSEN';
    case 'special':
      return 'Capacidades Diferentes';
    case 'wheelchair_handicap':
      return 'Capacidades Diferentes';
    case 'teacher_student_discount':
      return 'Descuento Estudiante/Profesor';
    default:
      return 'Boleto (s)';
  }
}

/**
 * Translates the service type code to a proper name
 * ```
 * economic => Econónimo
 * first_class => Primera Clase
 * luxury => Lujo
 * executive => Ejecutivo
 * ```
 * @method serviceName
 * @param {String} service Service code received from the API
 * @return {String}
 */
export function serviceName(service) {
  switch (service) {
    case 'economic':
      return 'Económico';
    case 'first_class':
      return 'Primera Clase';
    case 'luxury':
      return 'De Lujo';
    case 'executive':
      return 'Ejecutivo';
    case 'executive_plus':
      return 'Ejecutivo Plus';
    default:
      return service;
  }
}

/**
 * Translates an hour range to a description
 * ```
 * 0-6 => earlymorning
 * 7-11 => morning
 * 13-18 => afternoon
 * 19-23 => night
 * ```
 * @method hourDescription
 * @param {Number} hour Hour to translate 0 - 23
 * @return {String}
 */
export function hourDescription(hour) {
  if (hour >= 0 && hour < 6) {
    return 'earlymorning';
  }
  if (hour >= 6 && hour < 12) {
    return 'morning';
  }
  if (hour >= 12 && hour < 19) {
    return 'afternoon';
  }

  return 'night';
}

/**
 * Returns whether a trips arrives a day after it departs
 * @method arrivesNextDay
 * @param {Object} departureDate Moment object
 * @param {Object} arrivalDate Moment object
 * @return {Boolean}
 */
export function arrivesNextDay(departureDate, arrivalDate) {
  return arrivalDate.isAfter(departureDate, 'd');
}

/**
 * Returns whether a flight with connections has several carriers
 * @method hasMultipleCarriers
 * @param {Array} legs Flight connections
 * @return {Boolean}
 */
export function hasMultipleCarriers(legs) {
  if (legs.length < 2) return false;

  const firstCarrier = legs[0].carrierId;

  return !legs.every((leg) => leg.carrierId === firstCarrier);
}

/**
 * Receives a passengers object with the type as the key and the quantity
 * as the value and returns a string with the passengers encoded for
 * query params or for the URL
 * ```
 * if type is queryParams:
 *    { adult: 2, child: 1, infant: 1 } => adult=2&child=1&infant=1
 * else:
 *    { adult: 2, child: 1, infant: 1 } => A2-C1-I1
 * ```
 * @method encodePassengers
 * @param {Object} passengers Passengers object
 * @return {String}
 */
export function encodePassengers(passengers) {
  const searchPassengersAB = growthBook.getGrowthBookFeature('search_passengers');
  const decamelizedPassengerSelection = decamelizeKeys(passengers);
  const passengerTypes = Object.keys(decamelizedPassengerSelection);
  const encoded = [];

  passengerTypes.forEach((passengerType) => {
    const quantity = decamelizedPassengerSelection[passengerType];
    if (quantity > 0) {
      encoded.push(
        searchPassengersAB
          ? `${passengerType}-${quantity}`
          : `${passengerType.toUpperCase().charAt(0)}${quantity}`,
      );
    }
  });
  return searchPassengersAB ? encoded.join(',') : encoded.join('-');
}

/**
 * Checks if the passengers passed in the search have the new format
 * @param {String} passengers - URL encoded passengers
 * @returns
 */
export function isOldSearchPassengersFormat(passengers) {
  return passengers.includes('A1');
}

/**
 * Decodes the passenger types from a string format.
 * @param {String} passengers - URL encoded passengers
 * @returns {Object} - Decoded passenger types
 */
export function newDecodePassengers(passengers) {
  const passengerTypes = passengers.split(',');

  return passengerTypes.reduce((acc, passenger) => {
    const passengerValue = passenger.split('-');

    if (passengerValue.length === 1) return acc;
    acc = {
      ...acc,
      [passengerValue[0]]: Number(passengerValue[1]),
    };
    return acc;
  }, {});
}

/**
 * Receives a string with the URL encoded passengers and returns
 * an object with the type as the key and the quantity as the value
 * ```
 * A2-C1-I1 => { adult: 2, child: 1, infant: 1 }
 * ```
 * @method decodePassengers
 * @param {String} passengers URL encoded passengers
 * @return {Object}
 */
export function decodePassengers(passengers) {
  const isNewVersion = !isOldSearchPassengersFormat(passengers);
  if (isNewVersion) {
    return newDecodePassengers(passengers);
  }

  const passengerTypes = passengers.split('-');
  const decoded = {};

  passengerTypes.forEach((passengerType) => {
    const type = passengerType.substr(0, 1);
    const quantity = Number(passengerType.substr(1));

    switch (type) {
      case 'C': // children
        decoded.child = quantity;
        break;
      case 'I': // infants
        decoded.infant = quantity;
        break;
      default:
        // adults
        decoded.adult = quantity;
    }
  });

  return decoded;
}

/**
 * Receives a passengers object with the type as the key and the quantity
 * as the value and returns the total number of passengers
 * ```
 * { adult: 2, child: 1, infant: 1 } => 4
 * ```
 * @method passengerCount
 * @param {String} passengers URL encoded passengers
 * @return {Number}
 */
export function passengerCount(passengers) {
  return Object.keys(passengers)
    .map((passengerType) => parseInt(passengers[passengerType], 10))
    .reduce((prevValue, currValue) => prevValue + currValue, 0);
}

/**
 * Receives a ticket array and returns an array with the
 * category count and amount
 * ```
 * [
 *  { category: adult, ..., amount: 270 },
 *  { category: child, ..., amount: 150 }
 *  { category: child, ..., amount: 150 }
 * ] => [
 *  { adult: { count: 1, total: 270 } },
 *  { child: { count: 2, total: 300 } }
 * ]
 * ```
 * @method ticketPriceByCategory
 * @param {String} tickets Ticket array
 * @param {Number} tripCount Number of fragments in a purchase
 * @return {Array}
 */
export function ticketPriceByCategory(tickets, tripCount) {
  const totals = tickets.reduce((result, ticket) => {
    const { category, amount } = ticket;
    const item = result[category];

    if (item) {
      item.count += 1;
      item.total += Number(amount);
    } else {
      result[category] = {
        category,
        count: 1,
        total: Number(amount),
      };
    }

    return result;
  }, {});

  return Object.keys(totals).map((category) => ({
    ...totals[category],
    count: totals[category].count / tripCount, // Fixes duplicate category count on multicarrier
  }));
}

/**
 * Returns the correct funnel step for the checkout depending if the lines allow seat selection
 * ```
 * if single or round trip with no seat selection => 2
 * if single or round trip with seat selection for just one trip => 3
 * if round trip with seat selection for both trips => 4
 * ```
 * @method checkoutFunnelStep
 * @param {Object} dTrip Departure trip
 * @param {Object} rTrip Return trip
 * @return {Number}
 */
export function checkoutFunnelStep(departs, returns = {}) {
  let step = 2;

  if (departs.transportType === 'bus' && departs.fragments[0].line.allowsSeatSelection) {
    step += 1;
  }

  if (returns.transportType === 'bus' && returns.fragments[0].line.allowsSeatSelection) {
    step += 1;
  }

  return step;
}

// Temporal function to calculate multicarrier trip durations
export function calculateMulticarrierDuration(departure, arrival) {
  const msDeparture = moment(departure).valueOf();
  const msArrival = moment(arrival).valueOf();
  const msDuration = msArrival - msDeparture;

  return moment.duration(msDuration).asMinutes();
}

/**
 * Returns whether the return trip is elegible for a round trip discount, the following
 * conditions must be met:
 *  Both trips must be bus trips
 *  Both trips must be on the same line
 *  Both trips must have the same terminals as origin and destination
 *
 * @method hasRoundTripDiscount
 * @param {Object} departureTrip
 * @param {Object} returnTrip
 * @return {Boolean}
 */
export function hasRoundTripDiscount(departureTrip, returnTrip) {
  if (![departureTrip.transportType, returnTrip.transportType].every((tt) => tt === 'bus')) {
    return false;
  }

  if (departureTrip.line.id !== returnTrip.line.id) {
    return false;
  }

  if (
    departureTrip.originId !== returnTrip.destinationId ||
    departureTrip.destinationId !== returnTrip.originId
  ) {
    return false;
  }

  return true;
}

/**
 * Returns the total number of stops for a given trip
 *  - If bus => 0
 *  - If flight => legs - 1
 *  - If mixed => fragments - 1 [+ legs - 1, for flight fragments]
 *
 * @method stopoverCount
 * @param {Object} trip Can be a bus, flight or mixed trip
 * @return {Number}
 */
export function stopoverCount(trip) {
  const { transportType, legs, fragments } = trip;
  let stops = 0;

  if (transportType === 'flight') {
    stops = legs.length - 1;
  } else if (transportType === 'mixed') {
    stops = fragments.length - 1;
    fragments.forEach((fragment) => {
      stops += fragment.transportType === 'bus' ? 0 : fragment.legs.length - 1;
    });
  }

  return stops;
}

/**
 * Returns whether a place is a terminal or an airport accordion to it's slug
 * @param  {String} placeId The place slug
 * @return {String}         terminal | airport
 */
export function getPlaceType(placeId) {
  return placeId.match(/^t-/) ? 'terminal' : 'airport';
}

/**
 * Returns whether the trip's busline has seat selection
 *
 * @param  {Object}  reservation A purchase reservation
 * @return {Boolean}
 */
export function hasSeatSelection(reservation) {
  const { transportType, fragments } = reservation;
  const { line } = fragments[0];

  return transportType === 'bus' && line.allowsSeatSelection;
}

/**
 * Returns the product type
 * @returns {String} The product type
 */
export const getProductType = () => {
  const isDesktop = window.matchMedia('only screen and (min-width: 641px)').matches;
  return isDesktop ? 'desktop' : 'mobile';
};

/**
 * Checks if the screen size is desktop
 * @returns {Boolean}
 */
export const isDesktopSize = () => {
  return window.matchMedia('only screen and (min-width: 1081px)').matches;
};

/**
 * Checks if the brand is IAMSA.
 *
 * @param {string} brand - The brand name.
 * @returns {boolean} - True if the brand is IAMSA, false otherwise.
 */
export const isIAMSA = (brand) => {
  return brand === 'etn' || brand === 'costaline' || brand === 'gho';
};
