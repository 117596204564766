import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import 'styles/components/purchase/SeatsPreviewPricing';
import {
  Button,
  Currency,
  Gap,
  Spacing,
  Text,
  TextCollapse,
  Visibility,
} from '@reservamos/elements';
import SeatsButtonWrapper from 'ui/atoms/SeatsButtonWrapper';
import NewSeat from 'ui/atoms/NewSeat';
import { getCurrencyDecimals, getCurrencyPrefix, getCurrencySuffix } from 'utils/currency';
import { useTranslation } from 'react-i18next';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import { discountTypes, earlyDiscountTypes } from 'models/parseTrip';
import { When } from 'react-if';
import { getTripPricing } from 'hooks/usePricingBeforeCheckout';
import PricingTooltipDetails from 'ui/atoms/PricingTooltipDetails';
import ToggleLabel from 'ui/molecules/ToggleLabel';
import 'styles/components/SeatsPreviewPricing';
import { isDesktopSize } from 'utils/Reserbus';
import AdjacentBadge from 'images/badges/adjacent-seat.svg';
import AdjacentToggle from 'ui/molecules/AdjacentToggle';
import PricingRow from 'ui/atoms/PricingRow';
import TripDiscountAdvice from 'components/search/TripDiscountAdvice';
import { isPreviewCategoriesEnabled } from 'utils/seats';
import SeatsAlert from './SeatsAlert';
import CategoriesPricing from './CategoriesPricing';

/**
 * Renders the price preview component.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.tripPricing - The trip pricing.
 * @param {Array} props.seatsSelected - The selected seats.
 * @param {Array} props.passengerTypes - The passenger types.
 * @param {string} props.ctaText - The call to action text.
 * @param {boolean} props.showWomanSwitch
 * @param {Function} props.onWomanSwitchChange
 * @param {boolean} props.womanSwitchValue
 * @param {boolean} props.womanPoliciesError
 * @param {boolean} props.showPetFriendly
 * @param {Function} props.onContinue - The on continue function.
 * @param {Function} props.onSelectAdjacent - The on select adjacent function.
 * @param {boolean} props.adjacentSwitchValue - The adjacent switch value.
 * @param {boolean} props.showAdjacentCard - The flag to show the adjacent card.
 * @param {string} props.id - The slug trip.
 * @returns {JSX.Element} The rendered component.
 */
function SeatsPreviewPricing({
  tripPricing,
  seatsSelected,
  passengerTypes,
  ctaText,
  showWomanSwitch,
  onWomanSwitchChange,
  womanSwitchValue,
  womanPoliciesError,
  showPetFriendly,
  onContinue,
  onSelectAdjacent,
  adjacentSwitchValue,
  showAdjacentCard,
  id,
}) {
  const passengerSelection = useSelector((state) => state.search.toJS().passengers);
  const passengersCount = seatsSelected.filter((seat) => !seat.isPickedAsAdjacent).length;
  const adjacentSeats = seatsSelected.filter((seat) => seat.isPickedAsAdjacent);
  const pricingPreview = getTripPricing({
    passengersCount,
    pricing: tripPricing,
    passengerSelection,
    passengerTypes,
  });
  const {
    total = 0,
    totalBeforeDiscount = 0,
    discount = 0,
    totalPercentDiscount,
    discountType,
    luggage = 0,
    taxes = 0,
    networks = 0,
    insurance = 0,
    passengerBreakdown,
  } = pricingPreview;

  const { features } = useSelector((state) => state.whitelabelConfig);
  const isFlat = features.FUNNEL_STYLE === 'FLAT';

  const { t } = useTranslation();
  const { brand } = useWhitelabelEnvs();
  const { VALID_CATEGORIES, PRICING_BEFORE_CHECKOUT } = useWhitelabelFeatures();
  const isDesktop = isDesktopSize();

  const validCategories =
    VALID_CATEGORIES?.filter((category) => category !== 'general' && category !== 'pet_friendly') ||
    [];

  const categories = validCategories
    .slice(0, 3)
    .map((category) => t(`passengers:type.${category}`, { context: 'simple' }))
    .join(', ');

  const firstText = `${t('general:the_discount_for')} `;
  const firstTextFormatted = !isDesktop
    ? `${firstText.charAt(0).toLocaleLowerCase()}${firstText.slice(1)}`
    : firstText;

  const validDiscount = discountTypes[discountType];
  const discountIcon = validDiscount?.icon?.[brand];

  const passengersLabel = `${passengersCount} ${t(
    passengersCount !== 1 ? 'passengers:label.passengers' : 'passengers:label.passenger',
  )}`;
  const hasDiscount = Boolean(total && discount);
  const hasSpecialLabelDiscount = Boolean(discountIcon && validDiscount?.inLabel);
  const discountLabel = hasSpecialLabelDiscount
    ? t(`trips:${validDiscount?.inLabel}`)
    : t('trips:label.save');
  const discountText = `${discountLabel} ${totalPercentDiscount}`;

  const categoriesComponent = (
    <When condition={total && categories.length}>
      <Text size="XS" textAlign="start">
        {!isDesktop && (
          <Text size="XS" elementType="span" weight="semibold">
            {`${t('general:approx_price')}, `}
          </Text>
        )}
        {firstTextFormatted}
        <Text size="XS" color="primary" elementType="span">
          {`${categories}, etc. `}
        </Text>{' '}
        {` ${t('general:you_will_added_next')}`}
      </Text>
    </When>
  );

  const hasAdjacentSeats = Boolean(adjacentSeats.length);

  const adjacentsTotal =
    hasAdjacentSeats && adjacentSeats[0].adjacentSeats.price * adjacentSeats.length;
  const totalToUse = hasAdjacentSeats ? total + adjacentsTotal : total;

  const useCategories = Boolean(passengerTypes.length >= 2) && isPreviewCategoriesEnabled();

  const womanSwitchComponent = showWomanSwitch && (
    <div data-testid="womanCheck">
      <ToggleLabel
        onChange={onWomanSwitchChange}
        value={womanSwitchValue}
        label={t('seats:confirm_woman')}
        iconType="woman"
        error={womanPoliciesError}
        fillBackground
      />
    </div>
  );

  const adjacentSwitchComponent = showAdjacentCard && (
    <AdjacentToggle onSelect={onSelectAdjacent} value={adjacentSwitchValue} />
  );

  const detailsTooltipComponent = Boolean(total && PRICING_BEFORE_CHECKOUT) && (
    <PricingTooltipDetails
      luggage={luggage}
      networks={networks}
      taxes={taxes}
      insurance={insurance}
    />
  );

  const totalComponent = Boolean(totalToUse) && (
    <Currency
      price={totalToUse}
      sign={getCurrencyPrefix()}
      currency={getCurrencySuffix()}
      decimals={getCurrencyDecimals()}
      size="L"
      weight="bold"
    />
  );

  const categoryByTypes = (
    <>
      {Object.entries(passengerBreakdown)
        .filter(([, value]) => value.count >= 1)
        .map(([key, value]) => (
          <CategoriesPricing
            key={key}
            type={key}
            discount={value.discountPorcent}
            subDiscounts={value.count}
            hasDiscountAnticipated={
              key === 'general' && earlyDiscountTypes.includes(discountType) && discountIcon
            }
            total={value.total}
            countWithSpecialDiscount={value.countWithSpecialDiscount}
            totalSpecialDiscount={value.totalSpecialDiscount}
            countWithoutSpecialDiscount={value.countWithoutSpecialDiscount}
            normalDiscountPercent={value.normalDiscountPercent}
            normalTotal={value.normalTotal}
          />
        ))}
      {hasAdjacentSeats && (
        <PricingRow
          label={t('seats:adjacent_seat.label')}
          price={adjacentsTotal}
          icon={AdjacentBadge}
          isSpaceBetween
        />
      )}
    </>
  );

  const categoriesInfoMobile = (
    <When condition={total && categories.length}>
      <TextCollapse
        hasChevron
        triggerTextCollapsed={t('seats:see_details_pricing')}
        triggerTextExpanded={t('seats:see_less')}
        triggerPosition="top"
        isPositionEnd
      >
        <Spacing size="S" vertical fullWidth flexGrow>
          <div style={{ position: 'absolute', left: '0', top: '0' }}>
            <TripDiscountAdvice
              discountType={discountType}
              discountAvailability={discountLabel}
              discountAmount={tripPricing.discountBase}
              tripTotal={tripPricing.total}
              way={id}
            />
          </div>

          {categoryByTypes}
        </Spacing>
      </TextCollapse>
    </When>
  );

  const categoriesInfo = <>{categoryByTypes}</>;

  const pricingInfo = (
    <>
      <PricingRow label={passengersLabel} price={totalBeforeDiscount} isSpaceBetween />
      {hasAdjacentSeats && (
        <PricingRow
          label={t('seats:adjacent_seat.label')}
          price={adjacentsTotal}
          icon={AdjacentBadge}
          isSpaceBetween
        />
      )}

      {hasDiscount ? (
        <PricingRow
          label={discountText}
          price={discount}
          hasDiscount
          isSpaceBetween
          icon={discountIcon}
        />
      ) : null}
    </>
  );
  // Activate this button when the changes to make it work with the search widget have been done
  const showEditPassengers = false;

  const ctaEditPassengers = (
    <When condition={showEditPassengers && total && categories.length}>
      <Button
        color="grayStrong"
        isRounded
        minHeight="22px"
        padding="S"
        fontSize="S"
        text="Editar pasajeros"
      />
    </When>
  );

  const disclaimerTotal = (
    <>
      {Boolean(totalToUse) && (
        <Text size="XS" color="grayLight">
          {t('general:price_variation_notice')}
        </Text>
      )}
    </>
  );

  const component = (
    <>
      {useCategories ? (
        <Spacing size="S" vertical>
          <Visibility type="hideForMedium">
            <Spacing justifyContent="space-between" alignItems="center" responsiveSize="XXS">
              <Text weight="bold" size="S">
                {t('general:approx_price')}
              </Text>

              <TripDiscountAdvice
                discountType={discountType}
                discountAvailability={discountLabel}
                discountAmount={tripPricing.discountBase}
                tripTotal={tripPricing.total}
                way={id}
              />
            </Spacing>

            <Spacing size="XS" vertical>
              <When condition={total}>{categoriesInfo}</When>

              <Spacing size="XXS" vertical alignItems="flex-end">
                {womanSwitchComponent}
                {adjacentSwitchComponent}
                {detailsTooltipComponent}
              </Spacing>
            </Spacing>
          </Visibility>
        </Spacing>
      ) : (
        <Spacing size="S" vertical>
          <Visibility type="hideForMedium">
            <Text weight="bold" size="S">
              {t('general:approx_price')}
            </Text>

            <Spacing size="XS" vertical>
              <When condition={total}>{pricingInfo}</When>

              <Spacing size="XXS" vertical alignItems="flex-end">
                {totalComponent}
                {womanSwitchComponent}
                {adjacentSwitchComponent}
                {detailsTooltipComponent}
                {categoriesComponent}
              </Spacing>
            </Spacing>
          </Visibility>
        </Spacing>
      )}

      {!total && (
        <Visibility type="hideForMedium">
          <Text textAlign="center" color="grayMedium">
            {ctaText}
          </Text>
          <br />
        </Visibility>
      )}

      {(showWomanSwitch || showAdjacentCard) && (
        <Visibility type="showForMobileOnly">
          <div className="switch-mobile">
            {womanSwitchComponent}
            {adjacentSwitchComponent}
          </div>
        </Visibility>
      )}

      {Boolean(seatsSelected.length === 0) && (
        <Visibility type="hideForMedium">
          <SeatsAlert padding="S" titleSize="S" titleMobileSize="M" />
        </Visibility>
      )}

      {useCategories ? (
        <>
          <Visibility type="hideForLargeOnly">
            <SeatsButtonWrapper
              ctaText={ctaText}
              onClick={onContinue}
              showContent={Boolean(seatsSelected.length || !isDesktop)}
            >
              <Spacing size="XS" vertical fullWidth>
                {Boolean(seatsSelected.length === 0) && (
                  <Visibility type="hideForMedium">
                    <SeatsAlert padding="S" titleSize="S" titleMobileSize="M" />
                  </Visibility>
                )}
                <Visibility type="showForMobileOnly">{categoriesInfoMobile}</Visibility>
                <Visibility type="hideForMobileOnly">{categoriesInfo}</Visibility>
                {detailsTooltipComponent}

                <Spacing
                  alignItems="center"
                  justifyContent={showEditPassengers ? 'space-between' : 'flex-end'}
                  fullWidth
                >
                  {ctaEditPassengers}
                  {totalComponent}
                </Spacing>
                {disclaimerTotal}
              </Spacing>
            </SeatsButtonWrapper>
          </Visibility>

          <Visibility type="hideForMedium">
            <Spacing vertical size="S">
              <Spacing
                justifyContent={showEditPassengers ? 'space-between' : 'flex-end'}
                alignItems="center"
                fullWidth
              >
                {useCategories && (
                  <>
                    {ctaEditPassengers}
                    {totalComponent}
                  </>
                )}
              </Spacing>
              {disclaimerTotal}
              <Button
                text={ctaText}
                variant="primary"
                padding="M"
                fontSize="S"
                mobilePadding="XXL"
                borderRadius="S"
                fullWidthOnSmall
                fullWidth
                onClick={onContinue}
              />
            </Spacing>
          </Visibility>
        </>
      ) : (
        <SeatsButtonWrapper
          ctaText={ctaText}
          onClick={onContinue}
          showContent={Boolean(seatsSelected.length || !isDesktop)}
        >
          <Visibility type="hideForLargeOnly">
            <Spacing size="S" vertical>
              <Visibility type="hideForLargeOnly">
                <SeatsAlert padding="S" titleSize="S" titleMobileSize="M" />
              </Visibility>

              {detailsTooltipComponent}

              <Spacing alignItems="center" justifyContent="space-between">
                {categoriesComponent}
                {totalComponent}
              </Spacing>
            </Spacing>
          </Visibility>

          <Visibility type="hideForMedium">
            <Spacing justifyContent="center" alignItems="center">
              <When condition={seatsSelected.length}>
                <Text size="S" color="grayMedium" whiteSpace="nowrap">
                  {t('seats:pet_counter:title')}
                </Text>
                <Gap size="S">
                  {seatsSelected.map((seat) => (
                    <NewSeat
                      key={seat.number}
                      number={seat.number}
                      category={seat.category}
                      type="selected"
                      isSmall
                      newDesign
                      showPetFriendly={showPetFriendly}
                    />
                  ))}
                </Gap>
              </When>
            </Spacing>
          </Visibility>
        </SeatsButtonWrapper>
      )}
    </>
  );

  return isDesktop ? (
    <div
      className={`seats-preview-pricing ${isFlat ? 'seats-preview-pricing-flat' : ''} ${
        useCategories ? 'seats-preview-pricing-categories' : ''
      }`}
    >
      {component}
    </div>
  ) : (
    component
  );
}

SeatsPreviewPricing.propTypes = {
  tripPricing: PropTypes.object.isRequired,
  seatsSelected: PropTypes.array,
  passengerTypes: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      total: PropTypes.number.isRequired,
    }),
  ),
  ctaText: PropTypes.string,
  showWomanSwitch: PropTypes.bool,
  onWomanSwitchChange: PropTypes.func,
  womanSwitchValue: PropTypes.bool,
  womanPoliciesError: PropTypes.bool,
  showPetFriendly: PropTypes.bool,
  onContinue: PropTypes.func,
  onSelectAdjacent: PropTypes.func,
  adjacentSwitchValue: PropTypes.bool,
  showAdjacentCard: PropTypes.bool,
  id: PropTypes.string,
};

SeatsPreviewPricing.defaultProps = {
  seatsSelected: [],
  ctaText: '',
  onContinue: () => {},
};

export default SeatsPreviewPricing;
