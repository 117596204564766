import { camelizeKeys } from 'humps';
import { core as coreApi } from 'js-api-client';
import userFingerprint from '../userFingerprint';

/**
 * UserPreferencesService is a service for managing user preferences.
 */
const UserPreferencesService = () => {
  /**
   * Retrieves recommended trips based on the search ID.
   * @param {Object} options - The options for retrieving recommended trips.
   * @param {string} options.searchId - The search ID.
   * @returns {Promise} A promise that resolves with the recommended trips.
   * @throws {Error} If no distinct ID is available.
   */
  const getRecommendedTrips = ({ searchId }) => {
    return new Promise((resolve, reject) => {
      const userFingerprintId = userFingerprint.getFingerprint();
      if (!userFingerprintId) return reject(new Error('No fingerprint id'));

      coreApi
        .getRecommendedTrips({ searchId, userIdentifier: userFingerprintId })
        .then((response) => resolve(camelizeKeys(response)))
        .catch((error) => reject(error));
    });
  };

  return {
    getRecommendedTrips,
  };
};

const userPreferencesService = UserPreferencesService();
export default userPreferencesService;
